.content {
  text-align: left !important;
  padding-left: 0.6rem;
}

.info {
  padding: 0.2rem 0 !important;
}

.info_inner {
  display: flex;
  align-items: center;
}

.info_inner .pic {
  margin-right: 0.2rem;
  flex-shrink: 0;
  cursor: pointer;
}

.info_inner .pic img {
  width: 0.8rem;
  height: 1.2rem;
  object-fit: cover;
  display: block;
}

.info_inner .txt h2 {
  font-size: 0.18rem;
  color: #333333;
  line-height: 1;
  margin-bottom: 0.12rem;
  cursor: pointer;
}

.info_inner .txt h2:hover {
  color: #f6a75c;
}

.info_inner .txt .des {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.5;
  height: 3em;
}