.content {
  text-align: left !important;
  padding-left: 0.6rem;
}

td {
  height: 0.6rem !important;
}

td h2 {
  font-size: 0.16rem;
  color: #555555;
  cursor: pointer;
}

td h2:hover {
  color: #f6a75c;
}